let OAUTH = '';
let ADM = '';
let API = '';

if (process.env.REACT_APP_ENV === 'dev') {
  // homolog
  // change to this in future
  // OAUTH = 'https://homologacao.oauth.azagros.com';
  // ADM = 'https://homologacao.adm.azagros.com';
  // API = 'https://homologacao.api.azagros.com';

  OAUTH = 'https://oauth.azagros.com';
  ADM = 'https://adm.azagros.com';
  API = 'https://api.azagros.com';
} else if (process.env.REACT_APP_ENV === 'prod') {
  // prod
  OAUTH = 'https://oauth.azagros.com';
  ADM = 'https://adm.azagros.com';
  API = 'https://api.azagros.com';
} else if (process.env.REACT_APP_ENV === 'test') {
  // test
  // change to this in future
  // OAUTH = 'https://homologacao.oauth.azagros.com';
  // ADM = 'https://homologacao.adm.azagros.com';
  // API = 'https://homologacao.api.azagros.com';

  OAUTH = 'https://oauth.azagros.com';
  ADM = 'https://adm.azagros.com';
  API = 'https://api.azagros.com';
} else if (process.env.REACT_APP_ENV === 'develop') {
  // develop
  OAUTH = 'https://oauth-develop.azagros.com';
  ADM = 'https://adm-develop.azagros.com';
  API = 'https://api-develop.azagros.com';  

  // Prod for tests
  // OAUTH = 'https://oauth.azagros.com';
  // ADM = 'https://adm.azagros.com';
  // API = 'https://api.azagros.com';
} else if (process.env.REACT_APP_ENV === 'local') {
  //local
  OAUTH = 'http://localhost:8080';
  ADM = 'http://localhost:8090';
  API = 'http://localhost:8100';
}

export default {
  OAUTH: OAUTH,
  ADM: ADM,
  API: API
};
