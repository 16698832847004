import React, { Component, createRef } from 'react';
import { getUserLang } from '@utils/localeUtils';

class Map extends Component {
  constructor(props) {
    super(props);

    this.state = {
      overlayComplete: null,
      setAtNew: null,
      insertAtNew: null,
      setAtUpdate: null,
      insertAtUpdate: null,
      clickMarker: null,
      itensUniMedida: ['acre', 'alq.', 'ha', 'm2']
    };

    this.showPinInMap = this.showPinInMap.bind(this);
    this.createPolygon = this.createPolygon.bind(this);
  }

  componentDidMount() {
    let center = {
      
      lat: -25.744472026704535,
      lng: -53.046541213989265
    };
    let zoom = 3;

    if (this.props.latLong && this.props.latLong.nrLatitude && this.props.latLong.nrLongitude) {
      center = {
        lat: parseFloat(this.props.latLong.nrLatitude),
        lng: parseFloat(this.props.latLong.nrLongitude)
      };
      zoom = 15;
    } else if (
      this.props.areaCoord &&
      this.props.areaCoord.nrLatitude &&
      this.props.areaCoord.nrLongitude
    ) {
      center = {
        lat: parseFloat(this.props.areaCoord.nrLatitude),
        lng: parseFloat(this.props.areaCoord.nrLongitude)
      };

      zoom = 15;
    } else {
      if (
        this.props.navigatorPosition.latitude !== undefined &&
        this.props.navigatorPosition.longitude !== undefined
      ) {
        center = {
          lat: this.props.navigatorPosition.latitude,
          lng: this.props.navigatorPosition.longitude
        };

        zoom = 15;
      }
    }

    let map = new window.google.maps.Map(this.googleMapRef.current, {
      zoom: zoom,
      streetViewControl: false,
      center: center
    });

    let drawingManager = new window.google.maps.drawing.DrawingManager({
      drawingControl:
        this.props.valueCoords.length === 0 && this.props.valueCoordsAreaCultivo === undefined,
      drawingControlOptions: {
        position: window.google.maps.ControlPosition.RIGHT_CENTER,
        drawingModes: ['polygon']
      },
      polygonOptions: {
        draggable: true,
        editable: true,
        fillColor: 'rgb(105, 204, 174, 1)',
        strokeColor: '#000000',
        fillOpacity: 0.6
      }
    });

    drawingManager.setMap(map);
    map.setMapTypeId('hybrid');

    
    if (center.lat > 0 && center.lng > 0) {
      center = {
        lat: -25.744472026704535,
        lng: -53.046541213989265
      }
    }
      

    let setAtNew = {};
    let insertAtNew = {};
    let setAtUpdate = {};
    let insertAtUpdate = {};

    let overlayComplete = window.google.maps.event.addListener(
      drawingManager,
      'overlaycomplete',
      event => {
        let values = event.overlay
          .getPaths()
          .getAt(0)
          .getArray();

        event.overlay.getPaths().forEach(path => {
          insertAtNew = window.google.maps.event.addListener(path, 'insert_at', () => {
            let doc = path.getArray();
            let docFinal = [];

            doc.forEach(doc => {
              docFinal.push({
                lng: doc.lng(),
                lat: doc.lat()
              });
            });

            let hectares = (
              window.google.maps.geometry.spherical.computeArea(event.overlay.getPath()) * 0.0001
            ).toFixed(3);
            this.props.onClickMap(docFinal, hectares);
          });

          setAtNew = window.google.maps.event.addListener(path, 'set_at', () => {
            let doc = path.getArray();
            let docFinal = [];
            doc.forEach(doc => {
              docFinal.push({
                lng: doc.lng(),
                lat: doc.lat()
              });
            });
            let hectares = (
              window.google.maps.geometry.spherical.computeArea(event.overlay.getPath()) * 0.0001
            ).toFixed(3);
            this.props.onClickMap(docFinal, hectares);
          });
        });

        this.setState({
          setAtNew: setAtNew,
          insertAtNew: insertAtNew
        });

        let valuesFinal = [];
        values.forEach(doc => {
          valuesFinal.push({
            lng: doc.lng(),
            lat: doc.lat()
          });
        });

        drawingManager.setMap(null);
        let hectares = (
          window.google.maps.geometry.spherical.computeArea(event.overlay.getPath()) * 0.0001
        ).toFixed(3);
        this.props.onClickMap(valuesFinal, hectares);
      }
    );

    if (this.props.valueCoords.length !== 0) {
      let bounds = new window.google.maps.LatLngBounds();
      for (let i = 0; i < this.props.valueCoords.length; i++) {
        let item = {
          lat: parseFloat(this.props.valueCoords[i].lat),
          lng: parseFloat(this.props.valueCoords[i].lng)
        };
        bounds.extend(item);
      }

      map.fitBounds(bounds);

      if (!this.props.withoutChangeMap) {
        let valuesLatLng = this.createPolygon(this.props.valueCoords, true);

        this.showPinInMap(
          this.props.valueCoords,
          map,
          this.props.selectTalhao.dsTalhao,
          this.props.selectTalhao.nrAreaTalhao
        );
        valuesLatLng.setMap(map);

        valuesLatLng.getPaths().forEach(path => {
          insertAtUpdate = window.google.maps.event.addListener(path, 'insert_at', () => {
            let doc = path.getArray();
            let docFinal = [];

            doc.forEach(doc => {
              docFinal.push({
                lng: doc.lng(),
                lat: doc.lat()
              });
            });

            let hectares = (
              window.google.maps.geometry.spherical.computeArea(valuesLatLng.getPath()) * 0.0001
            ).toFixed(3);
            this.props.onClickMap(docFinal, hectares);
          });

          setAtUpdate = window.google.maps.event.addListener(path, 'set_at', () => {
            let doc = path.getArray();
            let docFinal = [];

            doc.forEach(doc => {
              docFinal.push({
                lng: doc.lng(),
                lat: doc.lat()
              });
            });

            let hectares = (
              window.google.maps.geometry.spherical.computeArea(valuesLatLng.getPath()) * 0.0001
            ).toFixed(3);
            this.props.onClickMap(docFinal, hectares);
          });
        });
      }

      this.setState({
        setAtUpdate: setAtUpdate,
        insertAtUpdate: insertAtUpdate
      });
    } else if (this.props.latLong.nrLatitude && this.props.latLong.nrLongitude) {
      let coord = [
        {
          lat: parseFloat(this.props.latLong.nrLatitude),
          lng: parseFloat(this.props.latLong.nrLongitude)
        }
      ];

      this.showPinInMap(
        coord,
        map,
        this.props.selectTalhao.dsTalhao,
        this.props.selectTalhao.nrAreaTalhao
      );

      let values = this.createPolygon(coord, false);

      values.setMap(map);
    }

    let listCoord = [];
    let clickMarker = null;
    this.props.data.forEach(doc => {
      if (doc.idTalhao !== this.props.selectTalhao.idTalhao) {
        let coord = doc.talhaoGpsList.map(gps => {
          return {
            lat: gps.nrLatitude,
            lng: gps.nrLongitude
          };
        });

        if (doc.talhaoGpsList.length === 0 && doc.nrLatitude && doc.nrLongitude) {
          coord = [{ lat: parseFloat(doc.nrLatitude), lng: parseFloat(doc.nrLongitude) }];
        }

        const unidadeMedida = this.state.itensUniMedida[doc.tpUnidade]
        clickMarker = this.showPinInMap(coord, map, doc.dsTalhao, doc.nrAreaTalhao, unidadeMedida);

        let values = this.createPolygon(coord, false);

        values.setMap(map);

        if (coord.length > 0) {
          listCoord.push(coord);
        }
      }
    });

    this.setState({
      overlayComplete: overlayComplete,
      clickMarker: clickMarker
    });

    if (
      listCoord.length > 0 &&
      this.props.valueCoordsAreaCultivo !== undefined &&
      this.props.selectAreCultivo
    ) {
      let boundsAreaCultivo = new window.google.maps.LatLngBounds();
      const talhaoWithPin = this.props.valueCoordsAreaCultivo.filter(
        talhao => talhao.talhaoGpsList.length === 0 && talhao.nrLatitude && talhao.nrLongitude
      );

      listCoord.forEach(doc => {
        for (let j = 0; j < doc.length; j++) {
          let itemAreaCultivo = {
            lat: parseFloat(doc[j].lat),
            lng: parseFloat(doc[j].lng)
          };
          boundsAreaCultivo.extend(itemAreaCultivo);
        }
      });

      talhaoWithPin.forEach(talhao => {
        let itemAreaCultivo = {
          lat: parseFloat(talhao.nrLatitude),
          lng: parseFloat(talhao.nrLongitude)
        };
        boundsAreaCultivo.extend(itemAreaCultivo);
      });

      map.fitBounds(boundsAreaCultivo);
    }
  }

  createPolygon(valueCoords, editable) {
    return new window.google.maps.Polygon({
      paths: valueCoords,
      draggable: editable,
      editable: editable,
      fillColor: 'rgb(105, 204, 174, 1)',
      strokeColor: '#000000',
      fillOpacity: 0.6
    });
  }

  showPinInMap(latLgnList, map, dsTalhao, nrAreaTalhao, unidadeMedida) {
    if (!unidadeMedida){
      unidadeMedida = this.state.itensUniMedida[this.props.selectTalhao.tpUnidade]
    }
    let stringText =
      '<p><b>' +
      dsTalhao +
      '</b></p> ' +
      '<p> ' +
      this.props.label +
      ': ' +
      Intl.NumberFormat(getUserLang()).format(nrAreaTalhao) + ' ' +
      unidadeMedida + 
      '</p>';

    let valores = {
      lat: 0,
      lng: 0
    };
    let count = 0;

    let infowindow = new window.google.maps.InfoWindow({
      content: stringText
    });

    latLgnList.forEach(item => {
      valores = {
        lat: valores.lat + item.lat,
        lng: valores.lng + item.lng
      };
      count = count + 1;
    });

    let media = {
      lat: valores.lat / count,
      lng: valores.lng / count
    };

    let marker = new window.google.maps.Marker({
      position: media
    });

    let clickMarker = marker.addListener('click', () => {
      infowindow.open(map, marker);
    });

    marker.setMap(map);

    return clickMarker;
  }

  componentWillUnmount() {
    let overlayComplete = this.state.overlayComplete;
    let setAtNew = this.state.setAtNew;
    let insertAtNew = this.state.insertAtNew;
    let setAtUpdate = this.state.setAtUpdate;
    let insertAtUpdate = this.state.insertAtUpdate;
    let clickMarker = this.state.clickMarker;

    if (setAtNew !== null) {
      setAtNew.remove();
      insertAtNew.remove();
    }

    if (setAtUpdate !== null && !this.props.withoutChangeMap) {
      setAtUpdate.remove();
      insertAtUpdate.remove();
    }

    if (clickMarker !== null) {
      clickMarker.remove();
    }

    overlayComplete.remove();
  }

  get googleMapDiv() {
    return document.getElementById('google-map');
  }

  googleMapRef = createRef();

  render() {
    console.log(this.props)
    return (
      <div
        style={{
          height: 'calc(100% - 10px)'
        }}
      >
        <div
          key={this.props.chave}
          id='google-map'
          ref={this.googleMapRef}
          style={{ width: '100%', height: '100%' }}
        />
      </div>
    );
  }
}
export default Map;
