import React, { useState, useEffect } from 'react';
import { IntlProvider, intlShape, injectIntl } from 'react-intl';
import { flattenMessages } from '@utils/languageUtils';
import { getUserLang } from '@utils/localeUtils';
import ptBR from './i18n/ptBR';
import enUS from './i18n/enUS';
import esES from './i18n/esES';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core';
import Loading from '@components/Loading/Loading';
import PDFViewer from '@components/PDFViewer/PDFViewer';
import swal from '@sweetalert/with-react';
import Relatorios from '@resources/Relatorio';
import Regioes from '@resources/Regiao';
import Localizacao from '@resources/Localizacao';
import Comunidade from '@resources/Comunidade';
import Cultura from '@resources/Cultura';
import Safra from '@resources/Safra';
import { FormattedMessage } from 'react-intl';
import CardsWithFilters from '@components/CardFilters/CardsWithFilters';
import * as moment from 'moment';
import RadioGroup from '@components/RadioGroup/RadioGroup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileCsv } from '@fortawesome/free-solid-svg-icons';

let messages = { 'pt-BR': ptBR, 'en-US': enUS, 'es-ES': esES };

var { provider } = new IntlProvider({});

const styles = {
  card: {
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: 'calc(100% - 15px)'
  },
  cardGrid: {
    marginTop: 30,
    marginBottom: 15,
    display: 'contents',
    width: '100%',
    flex: 1
  },
  page: {
    marginBottom: 15
  },
  document: {
    width: '100%'
  },
  divDocument: {
    width: '100%',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column'
  }
};

const messagesLinguage = flattenMessages(messages[getUserLang()]);
const relatorio = messagesLinguage['relatorio.relatorio'];
const regiao = messagesLinguage['relatorio.regiao'];
const municipio = messagesLinguage['relatorio.municipio'];
const sim = messagesLinguage['relatorio.sim'];
const nao = messagesLinguage['relatorio.nao'];
const carregando = messagesLinguage['relatorio.carregando'];
const gerandoRelatorio = messagesLinguage['relatorio.gerandoRelatorio'];
const falhaAoGerarRelatorio = messagesLinguage['relatorio.falhaAoGerarRelatorio'];
const fichaProdutor = messagesLinguage['relatorio.fichaProdutor'];
const tecnicoResponsavel = messagesLinguage['relatorio.tecnicoResponsavel'];
const crescente = messagesLinguage['relatorio.crescente'];
const decrescente = messagesLinguage['relatorio.decrescente'];
const produtor = messagesLinguage['relatorio.produtor'];
const uf = messagesLinguage['relatorio.uf'];
const minimoFiltro = messagesLinguage['relatorio.minimoFiltro'];
const mensagemSemFiltro = messagesLinguage['relatorio.mensagemSemFiltro'];
const estado = messagesLinguage['relatorio.estado'];
const comunidade = messagesLinguage['relatorio.comunidade'];
const safra = messagesLinguage['relatorio.safra'];
const cultura = messagesLinguage['relatorio.cultura'];
const periodo = messagesLinguage['relatorio.periodo'];
const baixarCSV = messagesLinguage['relatorio.baixarCSV'];

const intlProvider = new IntlProvider({
  locale: getUserLang(),
  messages: {
    relatorio,
    regiao,
    municipio,
    sim,
    nao,
    carregando,
    gerandoRelatorio,
    falhaAoGerarRelatorio,
    fichaProdutor,
    tecnicoResponsavel,
    crescente,
    decrescente,
    produtor,
    uf,
    minimoFiltro,
    mensagemSemFiltro,
    estado,
    comunidade,
    safra,
    cultura,
    periodo,
    baixarCSV
  }
});

provider = intlProvider.getChildContext();

const listRadiosGroup = [
  {
    value: 'regiao',
    check: false,
    disabled: false,
    label: provider.intl.formatMessage({ id: 'regiao' })
  },
  {
    value: 'estado',
    check: false,
    disabled: false,
    label: provider.intl.formatMessage({ id: 'estado' })
  },
  {
    value: 'cidade',
    check: false,
    disabled: false,
    label: provider.intl.formatMessage({ id: 'municipio' })
  },
  {
    value: 'comunidade',
    check: false,
    disabled: false,
    label: provider.intl.formatMessage({ id: 'comunidade' })
  }
];

const regiaoFilter = {
  label: provider.intl.formatMessage({ id: 'regiao' }),
  field: 'nmRegiao',
  list: 'listRegiao',
  type: 'autocomplete'
};

const estadoFilter = {
  label: provider.intl.formatMessage({ id: 'estado' }),
  field: 'nmUf',
  list: 'listEstado',
  type: 'autocomplete'
};

const municipioFilter = {
  label: provider.intl.formatMessage({ id: 'municipio' }),
  field: 'nmMunicipio',
  list: 'listMunicipio',
  type: 'autocomplete'
};

const comunidadeFilter = {
  label: provider.intl.formatMessage({ id: 'comunidade' }),
  field: 'nmComunidade',
  list: 'listComunidade',
  type: 'autocomplete'
};

const safraFilter = {
  label: provider.intl.formatMessage({ id: 'safra' }),
  field: 'dsSafra',
  list: 'listSafra',
  type: 'autocomplete'
};

const culturaFilter = {
  label: provider.intl.formatMessage({ id: 'cultura' }),
  field: 'nmCultura',
  list: 'listCultura',
  type: 'autocomplete'
};

const periodoFilter = {
  label: provider.intl.formatMessage({ id: 'periodo' }),
  field: 'dtPeriodo',
  type: 'date'
};

/**
 * Tela de Relatório de produtividade de grãos
 *
 * @author Gabriela Farias
 * @class Relatorio
 * @extends {Component} - Componente React
 */
function RelatorioProdutividadeGraos(props) {
  const [filePDF, setFilePDF] = useState(null);
  const [unitOfMeasure, setUnitOfMeasure] = useState(null);
  const [Lchips, setChips] = useState(null);
  const [showGroupOptions, setShowGroupOptions] = useState(false);
  const [fileXLS, setFileXLS] = useState(null);
  const [fileCSV, setFileCSV] = useState(null);
  const [closeCard, setCloseCard] = useState(false);
  const [errorCard, setErrorCard] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingDados, setLoadingDados] = useState(true);
  const [listMunicipio, setListMunicipio] = useState([]);
  const [listEstado, setListEstado] = useState([]);
  const [listComunidade, setListComunidade] = useState([]);
  const [listSafra, setListSafra] = useState([]);
  const [listRegiao, setListRegiao] = useState([]);
  const [listCultura, setListCultura] = useState([]);
  const [operationTypeString, setOperationTypeString] = useState([
    regiaoFilter,
    estadoFilter,
    municipioFilter,
    comunidadeFilter,
    safraFilter,
    culturaFilter,
    periodoFilter
  ]);
  const { classes } = props;

  useEffect(() => {
    const title = 'relatorio.graosProdutor';
    if (props.itensState.name !== 'relatorio.graosProdutor') {
      props.dispatch({
        type: 'UPDATE_TOOLBAR',
        toolbar: (
          <IntlProvider locale={getUserLang()} messages={flattenMessages(messages[getUserLang()])}>
            <FormattedMessage id={title} />
          </IntlProvider>
        ),
        name: title
      });
    }

    Promise.all([
      Regioes.findAllRegiao()
        .then(query => {
          setListRegiao(query.data);
        })
        .catch(() => {
          setListRegiao([]);
        }),
      Localizacao.findAllMunicipiosConcatenado()
        .then(query => {
          setListMunicipio(query.data);
        })
        .catch(err => {
          setListMunicipio([]);
        }),
      Localizacao.findAllEstados()
        .then(query => {
          setListEstado(query.data);
        })
        .catch(err => {
          setListEstado([]);
        }),
      Comunidade.findAllComunidades()
        .then(query => {
          setListComunidade(query.data);
        })
        .catch(err => {
          setListComunidade([]);
        }),
      Safra.findAllSafras()
        .then(query => {
          setListSafra(query.data);
        })
        .catch(err => {
          setListSafra([]);
        }),
      Cultura.findAllCultura()
        .then(query => {
          setListCultura(query.data);
        })
        .catch(err => {
          setListCultura([]);
        })
    ])
      .then(() => {
        setLoadingDados(false);
      })
      .catch(() => {
        setLoadingDados(false);
      });

    listRadiosGroup.forEach(item => {
      item.check = false;
    });
  }, []);

  const clickFiltrar = value => {
    let operation = [];

    const localidade = value.find(doc => {
      return (
        doc.filter.field === 'nmComunidade' ||
        doc.filter.field === 'nmUf' ||
        doc.filter.field === 'nmRegiao' ||
        doc.filter.field === 'nmMunicipio'
      );
    });

    if (localidade !== undefined) {
      if (localidade.filter.field === 'nmRegiao') {
        operation.push(regiaoFilter);
        listRadiosGroup.forEach(radio => {
          if (radio.value === 'regiao') {
            radio.disabled = true;
            radio.check = false;
          }
        });
      } else if (localidade.filter.field === 'nmUf') {
        operation.push(estadoFilter);
        listRadiosGroup.forEach(radio => {
          if (radio.value === 'regiao' || radio.value === 'estado') {
            radio.disabled = true;
            radio.check = false;
          }
        });
      } else if (localidade.filter.field === 'nmMunicipio') {
        operation.push(municipioFilter);
        listRadiosGroup.forEach(radio => {
          if (radio.value === 'regiao' || radio.value === 'estado' || radio.value === 'cidade') {
            radio.disabled = true;
            radio.check = false;
          }
        });
      } else if (localidade.filter.field === 'nmComunidade') {
        operation.push(comunidadeFilter);
        listRadiosGroup.forEach(radio => {
          radio.disabled = true;
          radio.check = false;
        });
      }
    } else {
      listRadiosGroup.forEach(radio => {
        radio.disabled = false;
      });
      operation.push(regiaoFilter, estadoFilter, municipioFilter, comunidadeFilter);
    }

    const dadosPlantio = value.find(doc => {
      return (
        doc.filter.field === 'nmCultura' ||
        doc.filter.field === 'dsSafra' ||
        doc.filter.field === 'dtPeriodo'
      );
    });

    if (dadosPlantio !== undefined) {
      if (dadosPlantio.filter.field === 'nmCultura' || dadosPlantio.filter.field === 'dtPeriodo') {
        operation.push(culturaFilter, periodoFilter);
      } else if (dadosPlantio.filter.field === 'dsSafra') {
        operation.push(safraFilter);
      }
    } else {
      operation.push(culturaFilter, safraFilter, periodoFilter);
    }

    setOperationTypeString(operation);
  };

  const gerarRelatorio = chips => {
    setShowGroupOptions(false);
    setErrorCard(false);
    setCloseCard(true);
    setLoading(true);
    setFilePDF(null);
    setFileXLS(null);
    setFileCSV(null);
    let unit = unitOfMeasure
  
    if (Lchips != null) {
      chips = Lchips
      setChips(null)
    }

    if (chips != null && chips.length) {
      setUnitOfMeasure('Ha')
      unit = 'Ha'
    }
    
    let municipioChip = chips.find(item => {
      return item.filter.field === 'nmMunicipio';
    });

    if (municipioChip !== undefined) {
      municipioChip = 'idMunicipio:' + municipioChip.value.value + ';';
    } else {
      municipioChip = '';
    }

    let regiaoChip = chips.find(item => {
      return item.filter.field === 'nmRegiao';
    });

    if (regiaoChip !== undefined) {
      regiaoChip = 'idRegiao:' + regiaoChip.value.value + ';';
    } else {
      regiaoChip = '';
    }

    let estadoChip = chips.find(item => {
      return item.filter.field === 'nmUf';
    });

    if (estadoChip !== undefined) {
      estadoChip = 'idUf:' + estadoChip.value.value + ';';
    } else {
      estadoChip = '';
    }

    let comunidadeChip = chips.find(item => {
      return item.filter.field === 'nmComunidade';
    });

    if (comunidadeChip !== undefined) {
      comunidadeChip = 'idComunidade:' + comunidadeChip.value.value + ';';
    } else {
      comunidadeChip = '';
    }

    let safraChip = chips.find(item => {
      return item.filter.field === 'dsSafra';
    });

    if (safraChip !== undefined) {
      safraChip = 'idSafra:' + safraChip.value.value + ';';
    } else {
      safraChip = '';
    }

    let culturaChip = chips.find(item => {
      return item.filter.field === 'nmCultura';
    });

    if (culturaChip !== undefined) {
      culturaChip = 'idCultura:' + culturaChip.value.value + ';';
    } else {
      culturaChip = '';
    }

    let dtPeriodo = '';
    chips.forEach(item => {
      if (item.filter.field === 'dtPeriodo') {
        let itemOperacao = item.operation;
        if (itemOperacao === '==') {
          itemOperacao = ':';
        } else if (itemOperacao === '!=') {
          itemOperacao = '<>';
        }

        dtPeriodo =
          dtPeriodo + 'dtPeriodo' + itemOperacao + moment(item.value).format('YYYY-MM-DD') + ';';
      }
    });

    let filters =
      municipioChip + regiaoChip + estadoChip + comunidadeChip + safraChip + culturaChip + dtPeriodo;

    listRadiosGroup.forEach(doc => {
      if (doc.check) {
        filters = filters + '&group=' + doc.value;
      }
    });

    Relatorios.gerarRelatorioProdutividadeGraos(filters, unit)
      .then(doc => {
        setCloseCard(false);
        setFileXLS(doc.data.dsLinkXls);
        setFilePDF(doc.data.dsLinkPdf);
        setFileCSV(doc.data.dsLinkCsv);
      })
      .catch(err => {
        modalErrorRelatorio();
      });
  };

  const modalErrorRelatorio = () => {
    setErrorCard(true);
    setCloseCard(false);
    setLoading(false);
    setFileXLS(null);
    setFilePDF(null);
    setFileCSV(null);
    setErrorCard(false);

    swal(provider.intl.formatMessage({ id: 'falhaAoGerarRelatorio' }), {
      icon: 'error',
      buttons: {
        confirm: {
          text: 'Ok',
          value: true,
          visible: true,
          closeModal: true,
          className: 'swal2-error'
        }
      }
    });
  };

  return (
    <IntlProvider locale={getUserLang()} messages={flattenMessages(messages[getUserLang()])}>
      <div className={classes.card}>
        {(loading || loadingDados) && <Loading />}
        <CardsWithFilters
          errorCard={errorCard}
          closeCard={closeCard}
          usePaddings={false}
          abaRadioGroup={<RadioGroup grid={6} itensRadio={listRadiosGroup} />}
          relatorio
          viewKey={'relatorio-produtividade-graos'}
          itens={[
            {
              autoComplete: {
                listMunicipio: {
                  list: listMunicipio,
                  campoOp: 'nmMunicipio',
                  campoChave: 'idMunicipio'
                },
                listEstado: {
                  list: listEstado,
                  campoOp: 'nmUf',
                  campoChave: 'idUf'
                },
                listComunidade: {
                  list: listComunidade,
                  campoOp: 'nmComunidade',
                  campoChave: 'idComunidade'
                },
                listSafra: {
                  list: listSafra,
                  campoOp: 'dsSafra',
                  campoChave: 'idSafra'
                },
                listRegiao: {
                  list: listRegiao,
                  campoOp: 'nmRegiao',
                  campoChave: 'idRegiao'
                },
                listCultura: {
                  list: listCultura,
                  campoOp: 'nmCultura',
                  campoChave: 'idCultura'
                }
              },
              radioButton: true,
              onClickButton: gerarRelatorio,
              onFilter: () => {},
              onFilterChanged: clickFiltrar,
              filtros: operationTypeString,
              viewKey: 'relatorio-produtividade-graos',
              keyCard: 1,
              relatorio: true,
              withoutButtonFiltrar: true,
              contain: props.chipData.find(data => {
                return data.id === 'relatorio-produtividade-graos';
              }).content
            },
            {
              contain: [],
              viewKey: 'relatorio-produtividade-graos',
              onClickButton: gerarRelatorio,
              onFilter: () => {},
              onFilterChanged: clickFiltrar,
              filtros: [],
              keyCard: 2,
              relatorio: true,
              withoutLabelTipo: true
            }
          ]}
        />
        {loading && (
          <div
            style={{
              display: 'flex',
              width: '100%',
              marginTop: 20,
              justifyContent: 'center'
            }}
          >
            {provider.intl.formatMessage({ id: 'gerandoRelatorio' })}
          </div>
        )}

        <PDFViewer
          withoutPosition={true}
          loadSucess={() => {
            setLoading(false);
          }}
          errorCard={errorCard}
          fileXLS={fileXLS}
          filePDF={filePDF}
          additionalActions={[
            {
              icon: (
                <FontAwesomeIcon
                  style={{ color: 'rgb(255, 255, 255)', width: 18, height: 18 }}
                  icon={faFileCsv}
                />
              ),
              name: provider.intl.formatMessage({ id: 'baixarCSV' }),
              file: fileCSV,
              color: '#42ADE8'
            }
          ]}
        />
      </div>
    </IntlProvider>
  );
}

const mapStateToProps = state => ({
  itensState: state.toolbar,
  infoUsuario: state.adicionarInfoUsuario.info,
  chipData: state.chips.chipData.filter(date => {
    return date.id === 'relatorio-produtividade-graos';
  })
});

RelatorioProdutividadeGraos.propTypes = {
  intl: intlShape.isRequired
};

let enhaced = injectIntl(withStyles(styles)(RelatorioProdutividadeGraos));
export default withRouter(connect(mapStateToProps)(enhaced));
